import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/template/blog-post.scss"
import SinglePostCover from "../components/BlogCover/SinglePostCover"
import Share from "../components/Share/Share"
import CallbackForm from "../components/CallbackForm/CallbackForm"
import MorePost from "../components/MorePost/MorePost"
import { ApolloProvider } from "@apollo/client"
import { client } from "../hooks/apolloClient"
import GravityFormForm from "gatsby-plugin-gravity-forms"

const BlogPostTemplate = ({ location, data, pageContext }) => {
  const [open, setOpen] = useState(false)
  const post = data?.post
  const seo = data?.seo
  const url = data?.site?.siteMetadata?.siteUrl + location.pathname
  const share = { url, title: seo?.seo?.title }
  const category = pageContext?.category
  const author = post?.author?.node

  const morePosts = data?.relatedPosts?.nodes?.filter(
    post => post?.id !== pageContext?.id
  )
  const featuredImageSrc = post?.featuredImage?.node?.sourceUrl
  const formSettings = data?.wp
  const handleFormSubmit = () => {
    // Redirect to the PDF URL
    window.location.href =
      "https://wirehouse.sd-clients.com/wp-content/uploads/2022/04/5x5-Risk-Assessment-template.pdf"
  }

  const successCallback = ({ data: formRes, reset }) => {
    // This will be called after the form is successfully submitted
    handleFormSubmit()
  }

  return (
    <ApolloProvider client={client}>
      <Layout>
        <SinglePostCover src={featuredImageSrc} />
        <div className="container main-post-wrapper">
          <div className="headline-post">
            {post?.title && <h1 itemProp="headline">{parse(post?.title)}</h1>}
            <p>
              {post?.date} | By: {post?.author?.node?.name}
            </p>
          </div>
          {post?.content && (
            <section className="main-post-content" itemProp="articleBody">
              {post?.blocks?.map((block, index) => {
                if (block.name === "acf/simple-form-picker") {
                  const data = block?.simpleFormPicker
                  const formData = {
                    wp: formSettings,
                    wpGfForm: data?.gfAcfPicker,
                  }
                  return (
                    <div className="form-wrapper simple-form-picker">
                      {data.formTitle.displayDefaultTitle
                        ? data?.gfAcfPicker?.title && (
                            <h3 className="form-title">
                              {data?.gfAcfPicker?.title}
                            </h3>
                          )
                        : data?.formTitle?.customTitle && (
                            <h3 className="form-title">
                              {data?.formTitle?.customTitle}
                            </h3>
                          )}
                      {data.formDescription?.displayDefaultDescription
                        ? data?.gfAcfPicker?.description && (
                            <p className="form-description">
                              {data?.gfAcfPicker?.description}
                            </p>
                          )
                        : data?.formDescription?.customDescription && (
                            <p className="form-description">
                              {data?.formDescription?.customDescription}
                            </p>
                          )}

                      <GravityFormForm
                        data={formData}
                        successCallback={successCallback}
                      />
                    </div>
                  )
                } else {
                  return <div key={index}>{ block?.saveContent ? parse(block?.saveContent) : parse(block?.originalContent)}</div>
                }
              })}
            </section>
          )}

          {post?.callBackButton?.showButton && (
            <div>
              <div
                onClick={() => {
                  setOpen(!open)
                }}
                className="call-back btn green"
              >
                <a href="#">{post?.callBackButton?.buttonText}</a>
              </div>
              <CallbackForm open={open} setOpen={setOpen} />
            </div>
          )}
          {author && (
            <div className="author">
              <div className="author-container">
                <div className="about-author">About the Author</div>
                <div className="author-content">
                  <div className="author-avatar">
                    <img src={author?.avatar?.url} alt={author?.name} />
                  </div>
                  <div className="author-info">
                    <div className="author-name">{author?.name}</div>
                    <div className="author-title">
                      {author?.customRole?.customRole
                        ? author?.customRole?.customRole
                        : author?.seo?.title}
                    </div>

                    <div className="author-bio">
                      <p>
                        {author?.description
                          ? author?.description
                          : author?.seo?.metaDesc}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <MorePost posts={morePosts} />
          <div className="share-post">
            <p>Share this with a friend: </p>
            <Share share={share} />
          </div>
        </div>
      </Layout>
    </ApolloProvider>
  )
}
export const Head = ({ data }) => (
  <Seo
    title={data?.seo?.seo?.title}
    description={data?.seo?.seo?.metaDesc}
    image={data?.seo?.featuredImage?.node?.sourceUrl}
    props={data?.seo?.seo}
  />
)
export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById($id: String!, $category: String!) {
    wp {
      ...GravityFormSettings
    }
    post: wpPost(id: { eq: $id }) {
      blocks {
        attributesJSON
        saveContent
        originalContent
        ... on WpAcfSimpleFormPickerBlock {
          name
          simpleFormPicker {
            gfAcfPicker {
              title
              description
              ...GravityFormFields
            }
            formDescription {
              customDescription
              displayDefaultDescription
            }
            formTitle {
              customTitle
              displayDefaultTitle
            }
          }
        }
      }
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      callBackButton {
        buttonText
        showButton
      }
      author {
        node {
          avatar {
            url
          }
          customRole {
            customRole
          }
          description
          name
          seo {
            title
            metaDesc
          }
        }
      }
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: NONE, formats: WEBP)
            }
          }
        }
      }
    }

    seo: wpPost(id: { eq: $id }) {
      featuredImage {
        node {
          sourceUrl
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    relatedPosts: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { uri: { eq: $category } } } }
      }
      limit: 6
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        featuredImage {
          node {
            localFile {
              name
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        id
        uri
      }
    }
  }
`
