import React from "react"
import "./morePost.scss"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Image from "../../helper/Image"

function MorePost({ posts }) {
  return (
    <div className="card-posts-grid">
      <h3 className="card-posts-title">More from the site</h3>
      <div className="card-posts-wrapper">
        {posts?.map(post => {
          return (
            <div className="card-post" key={post?.id}>
              <Link to={post?.uri}>
                {post?.featuredImage ? (
                  <Image image={post?.featuredImage?.node} alt={post?.title} />
                ) : (
                  <StaticImage
                    src="../../images/post-image.jpg"
                    alt={post?.title}
                    className="card-img"
                  />
                )}

                <p className="card-title">{post?.title}</p>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MorePost
