import React, { useState } from "react"
import "./blogCover.scss"
import CallbackForm from "../../components/CallbackForm/CallbackForm"
import bgImageHolder from "../../images/post-cover.jpg"

function SinglePostCover({ src }) {
  const [openCallback, setOpenCallback] = useState(false)
  return (
    <div
      className="post-cover"
      style={{
        backgroundImage: src ? `url(${src})` : `url(${bgImageHolder})`,
      }}
    >
      <div className="container post-container">
        <div
          className="callback-button"
          onClick={() => {
            setOpenCallback(!openCallback)
          }}
        >
          <p>Request a callback today »</p>
        </div>

        <CallbackForm open={openCallback} setOpen={setOpenCallback} />
      </div>
    </div>
  )
}

export default SinglePostCover
