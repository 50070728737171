import React, { useState } from "react"
import "./share.scss"

import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  TelegramShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TumblrShareButton,
  ViberShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share"

function Share({ share }) {
  const url = share.url
  const title = share.title
  const [open, setOpen] = useState(false)

  return (
    <div className="share-component">
      <div className="share-social">
        <LinkedinShareButton className="share-button" url={url} title={title}>
          <LinkedinIcon size={36} className="share-icon" />
        </LinkedinShareButton>
        <TwitterShareButton className="share-button" url={url} title={title}>
          <TwitterIcon size={36} className="share-icon" />
        </TwitterShareButton>

        <a
          href="#"
          className="share-more share-button"
          onMouseEnter={() => {
            setOpen(true)
          }}
          onMouseLeave={() => {
            setOpen(false)
          }}
        >
          <span className="share-more-svg">
            <svg
              focusable="false"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <g fill="#FFF">
                <path d="M14 7h4v18h-4z"></path>
                <path d="M7 14h18v4H7z"></path>
              </g>
            </svg>
          </span>
          <span className="share-more-text" data-a2a-localize="inner,Share">
            Share
          </span>
          <span className="share-more-count"></span>
        </a>
      </div>
      <div
        className={"share-more-menu " + `${open ? "flex" : "none"}`}
        onMouseEnter={() => {
          setOpen(true)
        }}
        onMouseLeave={() => {
          setOpen(false)
        }}
      >
        <FacebookShareButton className="share-button" url={url} title={title}>
          <FacebookIcon size={36} className="share-icon" />
          <span className="share-text">Facebook</span>
        </FacebookShareButton>
        <EmailShareButton className="share-button" url={url} title={title}>
          <EmailIcon size={36} className="share-icon" />
          <span className="share-text">Email</span>
        </EmailShareButton>
        <TelegramShareButton className="share-button" url={url} title={title}>
          <TelegramIcon size={36} className="share-icon" />
          <span className="share-text">Telegram</span>
        </TelegramShareButton>
        <TwitterShareButton className="share-button" url={url} title={title}>
          <TwitterIcon size={36} className="share-icon" />
          <span className="share-text">Twitter</span>
        </TwitterShareButton>
        <LinkedinShareButton className="share-button" url={url} title={title}>
          <LinkedinIcon size={36} className="share-icon" />
          <span className="share-text">Linkedin</span>
        </LinkedinShareButton>
        <FacebookShareButton className="share-button" url={url} title={title}>
          <FacebookMessengerIcon size={36} className="share-icon" />
          <span className="share-text">Messenger</span>
        </FacebookShareButton>
        <PocketShareButton className="share-button" url={url} title={title}>
          <PocketIcon size={36} className="share-icon" />
          <span className="share-text">Pocket</span>
        </PocketShareButton>
        <WhatsappShareButton className="share-button" url={url} title={title}>
          <WhatsappIcon size={36} className="share-icon" />
          <span className="share-text">Whatsapp</span>
        </WhatsappShareButton>
        <PinterestShareButton className="share-button" url={url} title={title}>
          <PinterestIcon size={36} className="share-icon" />
          <span className="share-text">Pinterest</span>
        </PinterestShareButton>
        <RedditShareButton className="share-button" url={url} title={title}>
          <RedditIcon size={36} className="share-icon" />
          <span className="share-text">Reddit</span>
        </RedditShareButton>
        <TumblrShareButton className="share-button" url={url} title={title}>
          <TumblrIcon size={36} className="share-icon" />
          <span className="share-text">Tumblr</span>
        </TumblrShareButton>
        <ViberShareButton className="share-button" url={url} title={title}>
          <ViberIcon size={36} className="share-icon" />
          <span className="share-text">Viber</span>
        </ViberShareButton>
      </div>
    </div>
  )
}

export default Share
